
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";
import { CONTENT_TYPE, ContentAssignment, ContentItem } from "@/types";
import ContentViewer from '@/components/ContentViewer.vue'
import { User } from "@/store/user/types";
import { USER_GETTERS } from "@/store/user/getters";
import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { firestore } from "@/firebase";

@Component({
  components: {
    ContentViewer
  }
})
export default class ViewContent extends Vue {
  assignment: ContentAssignment | null = null;
  item: ContentItem | null = null;

  videoDurationSeconds = 0;

  async mounted() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [])
    const assignment = await getDoc(doc(firestore, 'content-assignments', this.$route.params.id.toString()));
    if (!assignment.exists()) {
      this.$router.push('/404');
      return;
    }

    const item = await getDoc(doc(firestore, 'content-items', assignment.data().contentId));

    this.assignment = {
      ...assignment.data() as ContentAssignment,
      id: assignment.id,
    }

    this.item = {
      ...item.data() as ContentItem,
      id: item.id,
    }
  }

  setVideoDuration = (duration: number) => {
    this.videoDurationSeconds = duration;
  }

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  async complete() {
    await updateDoc(doc(firestore, 'content-assignments', this.$route.params.id.toString()), {
      completed: true,
    })

    if (!this.item) return;

    if (this.item.type === CONTENT_TYPE.VIDEO) {
      if (this.assignment && this.assignment.phase && !this.assignment.hoursAdded) {
        const activities = await getDocs(query(collection(firestore, 'activities'), where('phaseId', '==', this.assignment.phase)))
        for (let activity of activities.docs) {
          if (activity.data().toLowerCase().includes('screencast')) {
            await updateDoc(doc(firestore, 'content-assignments', this.$route.params.id.toString()), {
              hoursAdded: true,
            })
            // await addDoc(collection(firestore, 'actual-activities'), {
            //   activityId: activity.id,
            //   actualHours: 2,
            //   comment: this.item.title,
            //   date: new Date(),
            //   participantPresent: true,
            //   participantConfirmation: null,
            // })

            if(this.assignment.actualActivityId){
              await updateDoc(doc(firestore, 'actual-activities', this.assignment.actualActivityId), {
                participantConfirmed: true,
              })
            }
            return;
          }
        }
      }
    }

    await updateDoc(doc(firestore, 'content-assignments', this.$route.params.id.toString()), {
      hoursAdded: false,
    })

  }
}
